@tailwind base;
@tailwind components;
@tailwind utilities;

/* css background-image generated using https://10015.io/tools/css-background-pattern-generator */
.background {
  background-image: url("../src/assets/Images/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
}

/* css loader generated using https://10015.io/tools/css-loader-generator */
.spinner {
  margin: 40px;
  width: 11.2px;
  height: 11.2px;
  animation: spinner-z355kx 1s infinite linear;
  border-radius: 11.2px;
  box-shadow: 28px 0px 0 0 #fff, 17.4px 21.8px 0 0 #fff, -6.2px 27.2px 0 0 #fff,
    -25.2px 12px 0 0 #fff, -25.2px -12px 0 0 #fff, -6.2px -27.2px 0 0 #fff,
    17.4px -21.8px 0 0 #fff;
}

@keyframes spinner-z355kx {
  to {
    transform: rotate(360deg);
  }
}

.spinner2 {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%,#575766);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3.8px),#000 0);
  animation: spinner-zp9dbg 1s infinite linear;
}

@keyframes spinner-zp9dbg {
  to {
     transform: rotate(1turn);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
